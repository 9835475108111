import { FormLabel, Grid, styled } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    AttributeTitle,
    DecisionDto,
    DecisionType,
    FeatureFlags,
    GateStatus,
    GateTaskDto,
    MeasureDto,
    MeasureUpdate,
    UserDto,
} from "api-shared";
import { TFunction } from "i18next";
import { uniqBy } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../components/dialogues/ConfirmDialog";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import SingleUser from "../../../components/user/SingleUser";
import { useClientHasFeature } from "../../../domain/client";
import {
    IRequestDecisionInputDto,
    IUpdateDecisionInputDto,
    UpdateChangesInput,
    useDecisionForGateTask,
    useRequestDecision,
    useUpdateDecision,
} from "../../../domain/decision";
import { useProcessName } from "../../../domain/measure-config";
import { useCurrentUserCanEditMeasure } from "../../../domain/measure/detail";
import {
    useIsUserMeasureEditor,
    useMeasureEditorsQuery,
    useMeasurePermissionsAddUserMutation,
    useMeasurePermissionsQuery,
    useMeasureViewersQuery,
} from "../../../domain/measure/permission";
import { useUsersHavingPermissionQuery } from "../../../domain/permissions";
import { isActiveUser, useAllUsers, useCurrentUser } from "../../../domain/users";
import useDialog from "../../../hooks/useDialog";
import { useLanguage } from "../../../hooks/useLanguage";
import { Language, translationKeys } from "../../../translations/main-translations";
import { useMeasureContext } from "../../MeasureContext";
import DecisionBox from "./DecisionBox";
import DecisionFinishStep from "./DecisionFinishStep";
import DecisionRequest from "./DecisionRequest";
import DecisionSelectionStep from "./DecisionSelectionStep";
import DecisionStep from "./DecisionStep";

const Root = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

const Label = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.primary,
}));

export interface IDecisionGateWithDataProps {
    gateTask: GateTaskDto;
    updateFieldHandler: (changes: MeasureUpdate) => void;
    measure: MeasureDto;
    measureNotEditable: boolean;
    decision: DecisionDto | null;
    requestDecision: (input: IRequestDecisionInputDto) => void;
    isRequestCompletionInProgress: boolean;
    updateDecision: (input: IUpdateDecisionInputDto) => void;
    editors: UserDto[];
    viewers: UserDto[];
    deciders: UserDto[];
    ownDeciders: UserDto[];
    users: UserDto[];
    currentUser: UserDto | null;
    language: Language;
    translate: TFunction;
    processName: string;
    selfDecisionFeatureEnabled: boolean;
}

function getAvailableDecisions(gateTask: GateTaskDto) {
    return {
        canDecide: gateTask.decisionTypes != null && !!gateTask.decisionTypes.selfDecision,
        canRequest: gateTask.decisionTypes != null && !!gateTask.decisionTypes.otherDecider,
        canDocument: gateTask.decisionTypes != null && !!gateTask.decisionTypes.recordDecision,
    };
}

const DecisionGate = ({
    gateTask,
    updateFieldHandler,
    measure,
    measureNotEditable,
    decision,
    requestDecision,
    isRequestCompletionInProgress,
    updateDecision,
    editors,
    viewers,
    deciders,
    ownDeciders,
    users,
    currentUser,
    language,
    translate,
    processName,
    selfDecisionFeatureEnabled,
}: IDecisionGateWithDataProps) => {
    const isSelectableUser = (user: UserDto) => {
        if (!isActiveUser(user)) {
            return false;
        }

        const hasSelfDecisionFeature = selfDecisionFeatureEnabled;

        const { canDecide: hasSelfDecisionRule } = getAvailableDecisions(gateTask);

        const hasSelfDecisionPermission = ownDeciders.some((ownDecider) => ownDecider.id === currentUser?.id);

        const isCurrentUser = currentUser?.id === user.id;
        const isSelfDecisionEnabled = hasSelfDecisionFeature && hasSelfDecisionRule && hasSelfDecisionPermission;

        if (isCurrentUser && isSelfDecisionEnabled) {
            return true;
        }

        const isCurrentUserResponsible = measure.assignedToId === currentUser?.id;

        // if the current user is responsible all deciders should be selectable
        // else only deciders that are already editors
        return deciders
            .filter((decider) => isCurrentUserResponsible || editors.some((e) => e.id === decider.id))
            .some((decider) => decider.id === user.id);
    };

    const onRequestDecision = () => {
        if (decision !== null) {
            requestDecision({ decisionId: decision.id, measureId: gateTask.measureId });
        }
    };

    const onUpdateDecision = (changes: UpdateChangesInput) => {
        const { canDocument, canRequest } = getAvailableDecisions(gateTask);
        const selection = decision?.selection;
        if (!canDocument && canRequest && selection !== DecisionType.TYPE_REQUEST) {
            changes.selection = DecisionType.TYPE_REQUEST;
        }
        if (decision !== null) {
            updateDecision({ decisionId: decision.id, changes, measureId: gateTask.measureId });
        }
    };

    const onUpdateSelection = (selection: DecisionType) => onUpdateDecision({ selection });

    const onUpdateIsApproved = (isApproved: boolean) => onUpdateDecision({ isApproved });

    const onUpdateReason = (reason: string) => onUpdateDecision({ reason });

    const onUpdateDiscard = (key: string, value: number | string) => {
        if (value) {
            const field = key === "reason" ? AttributeTitle.DiscardReason : AttributeTitle.DiscardStatement;
            updateFieldHandler({ [field]: value });
        }
    };

    const confirmDialog = useDialog();

    const [newDecider, setNewDecider] = useState<{ id: number; isViewer: boolean } | null>(null);

    const updateUserMutation = useMeasurePermissionsAddUserMutation();

    const onUpdateDecisionMaker = () => {
        if (newDecider == null) {
            return;
        }

        // Assign editor first, then update decider
        updateUserMutation.mutate(
            { entityId: gateTask.measureId, id: newDecider.id, permission: AclPermissions.Update },
            {
                onSuccess: () => {
                    onUpdateDecision({ deciderId: newDecider.id });
                },
            },
        );
    };

    const shouldUpdateDecisionMaker = (deciderId: number | null) => {
        const isEditor = editors.some((editor) => editor.id === deciderId);
        if (isEditor || deciderId === null) {
            onUpdateDecision({ deciderId });
            return;
        }

        const isViewer = viewers.some((editor) => editor.id === deciderId);

        setNewDecider({ id: deciderId, isViewer: isViewer });
        confirmDialog.open();
    };

    const updateDecisionMaker = (deciderId: number | null) => shouldUpdateDecisionMaker(deciderId);

    const deciderIsEditor = useIsUserMeasureEditor(gateTask.measureId, decision?.deciderId ?? null);

    if (decision == null) {
        return <LoadingAnimation />;
    }

    const { deciderId, isApproved, requestedDeciderId, requesterId, selection, requestedAt } = decision;
    const { fields } = measure;

    const discardReason = fields.discardReason.value != null ? +fields.discardReason.value : null;
    const discardStatement = fields.discardStatement.value ?? "";

    const decider = users.find((u) => u.id === deciderId);

    const finished = gateTask.status === GateStatus.STATUS_COMPLETED;
    const disabled = measureNotEditable || finished;
    const isCurrentGateTask = measure.currentGateTaskConfigId === gateTask.gateTaskConfigId;
    const btnDisabled = disabled || isRequestCompletionInProgress || !isCurrentGateTask || !deciderIsEditor;
    const currentUserIsSelected = currentUser != null /* Full Measure view */ && deciderId === currentUser.id;

    const { canDocument, canRequest } = getAvailableDecisions(gateTask);

    // The effective group of deciders is the union of deciders (person with the permission to decide all measures)
    // AND the current user if he is responsible for the measure and has the self-decision permission (or is in deciders)
    const realDeciders = uniqBy(
        deciders.concat(ownDeciders.filter((ownDecider) => ownDecider.id === currentUser?.id && measure.assignedToId === ownDecider.id)),
        "id",
    );

    return (
        <Grid container component={Root}>
            <ConfirmDialog
                open={confirmDialog.isOpen}
                onClose={confirmDialog.close}
                item={translationKeys.VDLANG_CONFIRM}
                translate={translate}
                title={translate(translationKeys.VDLANG_MEASURE_DECIDER_SHOULD_ADD_TITLE)}
                onConfirm={onUpdateDecisionMaker}
            >
                {translate(translationKeys.VDLANG_MEASURE_DECIDER_SHOULD_ADD_TEXT, {
                    displayname: users.find((u) => u.id === newDecider?.id)?.displayname ?? "",
                })}
            </ConfirmDialog>
            <DecisionStep variant="dark" item xs={12} md={3} justifyContent="space-around">
                <FormLabel component={Label} disabled={disabled}>{`${translate("who_should_decide")} `}</FormLabel>
                <Grid container justifyContent="center">
                    <SingleUser
                        closeOnSelect
                        available={isSelectableUser}
                        users={realDeciders}
                        disabled={disabled}
                        update={updateDecisionMaker}
                        user={decider}
                        translate={translate}
                        variant="chip"
                        testId="decider"
                        avatarSize={32}
                    />
                </Grid>
            </DecisionStep>
            {!currentUserIsSelected && !finished && (canDocument || canRequest) && decider != null ? (
                <DecisionSelectionStep
                    disabled={disabled}
                    decider={decider}
                    selection={selection}
                    updateSelection={onUpdateSelection}
                    translate={translate}
                    canRequest={canRequest}
                    canDocument={canDocument}
                />
            ) : null}
            {!currentUserIsSelected && selection === DecisionType.TYPE_REQUEST && decider != null ? (
                <DecisionRequest
                    disabled={btnDisabled}
                    deciderIsEditor={deciderIsEditor}
                    isRequisiteGateTaskCompleted={isCurrentGateTask}
                    requestedDeciderId={requestedDeciderId}
                    decider={decider}
                    requestDecision={onRequestDecision}
                    translate={translate}
                    currentUser={currentUser}
                    requestedAt={requestedAt}
                    requesterId={requesterId}
                    users={users}
                />
            ) : null}
            {(currentUserIsSelected || selection === DecisionType.TYPE_DOCUMENT) && decider != null ? (
                <DecisionBox
                    decision={decision}
                    currentLanguage={language}
                    disabled={disabled}
                    discardReason={discardReason}
                    discardStatement={discardStatement}
                    translate={translate}
                    updateReason={onUpdateReason}
                    updateDiscard={onUpdateDiscard}
                    updatedIsApproved={onUpdateIsApproved}
                    currentUser={currentUser}
                    users={users}
                    noArrow={!finished}
                />
            ) : null}
            {finished && gateTask.completedById != null && gateTask.completedAt != null && decider != null ? (
                <DecisionFinishStep
                    isApproved={isApproved}
                    completedAt={gateTask.completedAt}
                    decider={decider}
                    translate={translate}
                    measureConfig={measure.measureConfig}
                    selection={selection}
                    requestedDeciderId={requestedDeciderId}
                    completedById={gateTask.completedById}
                    requesterId={requesterId}
                    users={users}
                    decisionReason={decision.reason}
                />
            ) : null}
        </Grid>
    );
};

const ConnectedDecisionGate = React.memo(DecisionGate);

const DecisionGateWithData = (props: Pick<IDecisionGateWithDataProps, "gateTask" | "updateFieldHandler">) => {
    const measure = useMeasureContext();
    const hasSelfDecisionFeature = useClientHasFeature(FeatureFlags.FEATURE_PROCESS_SELF_DECISION);
    const currentUserCanEditMeasure = useCurrentUserCanEditMeasure(measure);
    const decision = useDecisionForGateTask(measure.id, props.gateTask.id);
    const editorsQuery = useMeasureEditorsQuery(measure.id);
    const viewersQuery = useMeasureViewersQuery(measure.id);
    const decidersQuery = useMeasurePermissionsQuery({ permission: AclPermissions.Decide, measureId: measure.id });
    const ownDecidersQuery = useMeasurePermissionsQuery({ permission: AclPermissions.SelfDecision, measureId: measure.id });
    const usersHavingValuestreamPermissionQuery = useUsersHavingPermissionQuery({
        namespace: AclNamespaces.Valuestream,
        permission: AclPermissions.Read,
        fact: { id: measure.measureConfigId },
    });
    const requestDecisionMutation = useRequestDecision();
    const updateDecision = useUpdateDecision().mutate;
    const allUsers = useAllUsers();
    const currentUser = useCurrentUser();
    const { t: translate } = useTranslation();
    const language = useLanguage();
    const processName = translate(useProcessName(measure));

    if (
        !editorsQuery.isSuccess ||
        !viewersQuery.isSuccess ||
        !decidersQuery.isSuccess ||
        !ownDecidersQuery.isSuccess ||
        !usersHavingValuestreamPermissionQuery.isSuccess
    ) {
        return <LoadingAnimation />;
    }

    const viewers = allUsers.filter((u) => viewersQuery.data.combinedUserIds.includes(u.id));
    const editors = allUsers.filter((u) => editorsQuery.data.combinedUserIds.includes(u.id));
    const deciders = allUsers.filter(
        (u) =>
            decidersQuery.data.combinedUserIds.includes(u.id) && usersHavingValuestreamPermissionQuery.data.combinedUserIds.includes(u.id),
    );
    const ownDeciders = allUsers.filter(
        (u) =>
            ownDecidersQuery.data.combinedUserIds.includes(u.id) &&
            usersHavingValuestreamPermissionQuery.data.combinedUserIds.includes(u.id),
    );

    return (
        <ConnectedDecisionGate
            measure={measure}
            measureNotEditable={!currentUserCanEditMeasure}
            decision={decision}
            requestDecision={requestDecisionMutation.mutate}
            isRequestCompletionInProgress={requestDecisionMutation.isLoading}
            updateDecision={updateDecision}
            editors={editors}
            viewers={viewers}
            deciders={deciders}
            ownDeciders={ownDeciders}
            users={allUsers}
            currentUser={currentUser}
            translate={translate}
            language={language}
            processName={processName}
            selfDecisionFeatureEnabled={hasSelfDecisionFeature}
            {...props}
        />
    );
};

export default DecisionGateWithData;
