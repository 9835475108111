import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    EffectCategoryDto,
    EffectCategoryListDto,
    type CopyEffectCategoryDto,
    type CreateEffectCategoryDto,
    type EffectCategoryHistoryDto,
    type UpdateEffectCategoryDto,
} from "api-shared";
import queryString from "query-string";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { effectKeys } from "./effect";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { processHistoryKeys } from "./process-history";
import { ProcessPulseQueryKeys } from "./process-pulse";
import { ReportingQueryKeys } from "./reporting";
import { spanEffectKeys } from "./span-effect";

const EFFECT_CATEGORIES_PATH = "effect-categories";

export type UpdateEffectCategoryInput = UpdateEffectCategoryDto & {
    effectCategoryId: number;
};

export const effectCategoriesKeys = {
    forMeasure: (measureId: number) => [EFFECT_CATEGORIES_PATH, measureId] as const,
    forHistory: (id: number) => [EFFECT_CATEGORIES_PATH, id, "history"] as const,
};

export interface IRemoveEffectCategoryInputDto {
    id: number;
    measureId: number;
}

export const useEffectCategories = (measureId: number) => {
    return useQuery({
        queryKey: effectCategoriesKeys.forMeasure(measureId),
        queryFn: ({ signal, queryKey }) =>
            apiGet<EffectCategoryListDto>(`${EFFECT_CATEGORIES_PATH}?${queryString.stringify({ measureId: queryKey[1] })}`, { signal }),
    });
};

export const useAddEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CreateEffectCategoryDto) => apiPost<EffectCategoryDto>(EFFECT_CATEGORIES_PATH, data),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(spanEffectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
        },
    });
};

export const useUpdateEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ effectCategoryId, categoryFields, currencyId }: UpdateEffectCategoryInput) =>
            apiPatch<EffectCategoryDto>(`${EFFECT_CATEGORIES_PATH}/${effectCategoryId}`, { categoryFields, currencyId }),
        onSuccess: ({ measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
        },
    });
};

export const useCopyValuesFromPreviousCalculationLevel = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CopyEffectCategoryDto) => apiPost<EffectCategoryListDto>(`${EFFECT_CATEGORIES_PATH}/copy`, data),
        onSuccess: (response, data) => {
            const measureId = "measureId" in data ? data.measureId : response[0].measureId;
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(spanEffectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId), undefined);
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
        },
    });
};

export const useRemoveEffectCategory = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id }: IRemoveEffectCategoryInputDto) => apiDelete(`${EFFECT_CATEGORIES_PATH}/${id}`),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
        },
    });
};

export const useEffectCategoryHistory = (id: number) => {
    return useQuery({
        queryKey: effectCategoriesKeys.forHistory(id),
        queryFn: ({ signal, queryKey }) => apiGet<EffectCategoryHistoryDto>(`${EFFECT_CATEGORIES_PATH}/${queryKey[1]}/history`, { signal }),
        enabled: id > 0,
    });
};
