import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SpanEffectListDto, type SpanEffectUpdateDto } from "api-shared";
import queryString from "query-string";
import { apiGet, apiPatch } from "../lib/api";
import { effectKeys } from "./effect";
import { effectCategoriesKeys } from "./effect-category";
import { MeasureDetailQueryKeys } from "./measure/detail";
import { ProcessPulseQueryKeys } from "./process-pulse";
import { ReportingQueryKeys } from "./reporting";

const SPAN_EFFECT_PATH = "span-effect";

export const spanEffectKeys = {
    forMeasure: (measureId: number) => [SPAN_EFFECT_PATH, measureId] as const,
};

export interface IUpdateSpanEffectInput {
    spanEffectId: number;

    payload: SpanEffectUpdateDto;
}

export const useSpanEffects = (measureId: number) => {
    return useQuery({
        queryKey: spanEffectKeys.forMeasure(measureId),
        queryFn: ({ signal, queryKey }) =>
            apiGet<SpanEffectListDto>(`${SPAN_EFFECT_PATH}?${queryString.stringify({ measureId: queryKey[1] })}`, { signal }),
    });
};

export const useUpdateSpanEffect = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ spanEffectId, payload }: IUpdateSpanEffectInput & { measureId: number }) =>
            apiPatch(`${SPAN_EFFECT_PATH}/${spanEffectId}`, { ...payload }),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(spanEffectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(effectKeys.forMeasure(measureId));
            queryClient.invalidateQueries(effectCategoriesKeys.forMeasure(measureId));
        },
    });
};
